export default class Subscription {
    static subscribe(form) {
        $.ajax({
            type: "POST",
            dataType: "JSON",
            url: woocommerce_params.ajax_url,
            data: form.serialize() + '&action=subscription_mailchimp',
            success: function (response) {
                if (response.message) {
                    $(".mc4wp-response").html(response.message);
                }

                if (response.subscribed) {
                    $(".pop-up-text").html(`<p class="response-center">${response.message}</p>`);
                }
            }
        });
    }
}
