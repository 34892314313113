import 'slick-carousel';
import 'select2';
import ContactForm from '../classes/contact-form';
import Subscription from '../classes/subscription';
import WishList from '../classes/wishlist';
import Instafeed from 'instafeed.js';


export default {
    init() {
        let instafeed = $("#instafeed");
        if (typeof Instafeed !== "undefined" && instafeed.length) {
            const feed = new Instafeed({
                accessToken: instafeed.data('token'),
                template: '<a target="_blank" href="https://www.instagram.com/modern_iceland"><img src="{{image}}"></a>',
                sortBy: 'most-liked',
                limit: 5
            });

            feed.run();
        }

        $('.pdf-box a').prepend('<span class="icon-pdf"></span>');
    },
    finalize() {
        let self = this;

        // JavaScript to be fired on all pages, after page specific JS is fired
        $(document)
            .on('click touch', '.hamburger-button', function () {
                $('header .nav , .hamburger-button').toggleClass('active');
            })
            .on('click touch', '.item-remove', function () {
                $(this).closest('tr').remove();
            })
            .on('click touch', '.popup-close', function () {
                $('.base-popup').fadeOut();
            })
            .on('click touch', '.lupe-link', function (e) {
                e.preventDefault();
                $('.index-search-box').toggleClass('active');
                $('.index-search-box #woocommerce-product-search-field').focus();
            })
            .on('click touch', '.first-level li', function (e) {
                $(this).toggleClass('active');
                if ($(this).closest('li').children('ul').length) {
                    e.preventDefault();
                    $(this).closest('li').children('.second-level').slideToggle();
                }
            })
            .on('click', '.contact__send', (e) => {
                e.preventDefault();

                if (typeof woocommerce_params !== 'undefined') {
                    ContactForm.send();
                }
            })
            .on('change', '.numeric-input.qty', () => {
                $('[name=update_cart]').prop('disabled', false).trigger('click');
            })
            .on("click", "button[name=apply_coupon]", function (e) {
                e.preventDefault();

                $(this).closest('.discount-form').find('input[type=submit]').click();
            })
            .on('click', '.quantity .quantity-up', function () {
                let parentBlock = $(this).closest('.quantity');
                let input = parentBlock.find('input[type=number]');
                let max = input.attr('max');
                let oldValue = parseFloat(input.val());
                let newVal = (max !== '' && oldValue >= max) ? oldValue : (oldValue + 1);

                input.val(newVal);
                input.trigger('change');
            })
            .on('click', '.quantity .quantity-down', function () {
                let parentBlock = $(this).closest('.quantity');
                let input = parentBlock.find('input[type=number]');
                let min = input.attr('min');
                let oldValue = parseFloat(input.val());
                let newVal = (oldValue <= min) ? oldValue : (oldValue - 1);

                input.val(newVal);
                input.trigger('change');
            })
            .on('click', '.subscribe-form button[type=submit]', function (e) {
                e.preventDefault();

                let form = $(this).closest('form');

                Subscription.subscribe(form);
            })
            .on('click , touch', '.mobile-menu-button', function () {
                $(this).toggleClass('active');
                $('ul.navigation').toggleClass('active');
                $('body').toggleClass('active-menu');
            })
            .on('click touch', '.product-categories li.cat-parent', function (e) {
                if ($(e.target).closest('ul.children').length !== 0) {
                    return;
                }

                e.preventDefault();
                $(this).toggleClass('active');
            })
            .on('change', '.pwb-filter-products label', function () {
                $(this).toggleClass('clicked');
            })
            .on('click touch', '.brands .widgettitle', function () {
                $(this).toggleClass('active');
                $('.pwb-filter-products').slideToggle();
            })
            .on('click touch', '.range-price .widgettitle', function () {
                $(this).toggleClass('active');
                $('.aside-range-form').slideToggle('fast');
            })
            .on('click touch', '.search-link', function () {
                $('header .top-box').toggleClass('active');
                $('header .header-search').toggleClass('active');
                $('#woocommerce-product-search-field').focus();
            })
            .on('click touch', '.header-search .icon-header-cart-remove', function () {
                $('header .top-box').toggleClass('active');
                $('header .header-search').toggleClass('active');
            })
            .on('click touch', '.add-to-favorite .icon-basket', function () {
                $(this).closest('.cart-item').remove();
            })
            .on('click touch', '.options-toggle', function () {
                $(this).toggleClass('active');
                $('.options-box .inner-box').toggleClass('active');
            })
            .on('click touch', '.add-to-favorite', function () {
                $(this).children('.icon-favorite').toggle();
                $(this).children('.icon-heart-active').toggle();
            })
            .on('click touch', '.mobile-open-close', function () {
                let $this = $(this);
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                } else {
                    $('.mobile-open-close').removeClass('active');
                    $(this).addClass('active');
                }
                if ($this.next('.navigation-categories').hasClass('active')) {
                    $this.next('.navigation-categories').removeClass('active');
                } else {
                    $('.navigation-categories').removeClass('active');
                    $this.next('.navigation-categories').addClass('active');
                }
            })
            .on('click touch', '.product-options .options-row', function () {
                const sku = $(this).children('.js-trigger-option').data('sku').trim();
                $('select#pa_sku').val(sku).trigger('change');
                $('.added-to-cart-item').hide();
            })
            .on('click touch', '.mobile-aside-toggle', function () {
                $(this).toggleClass('active');
                $('.sidebar').slideToggle();
                $('.mobile-product-separator').toggleClass('active');
            })
            .on('yith-infs-scroll-finished yith_infs_adding_elem', function () {
                self.processProductsLoopGallery();
            })
            .on('click touch', '.product-options .options-row', function () {
                $('.product-options .options-row').removeClass('active');
                $(this).addClass('active');
            })
            .on('click touch', '.add-to-favorite .icon-favorite, .single_add_to_wishlist', function (e) {
                e.preventDefault();

                let productId = $(this).hasClass('single_add_to_wishlist')
                    ? $(this).data('product_id')
                    : $(this).closest('.add-to-favorite').data('product_id');

                let qty = $(this).siblings('.qty').val();
                let type = ($(this).hasClass('is-wedding')) ? 'wedding' : 'wish';

                if (isNaN(productId)) {
                    return;
                }

                productId = parseInt(productId);
                WishList.setProduct(productId, qty, type);
            })
            .on('click touch', '.add-to-favorite .icon-basket', function () {
                let productId = $(this).closest('.add-to-favorite').data('product_id');

                if (isNaN(productId)) {
                    return;
                }

                WishList.removeProduct(productId);
                $(this).closest('.product-item').remove();
            })
            .on('wc_cart_button_updated', function () {
                $('.added_to_cart.wc-forward').remove();
            })
            .on("added_to_cart", (event, fragments, cart_hash, $button) => {
                let cartLink = $('.search-cart-widget .cart-link');
                if (fragments && !cartLink.hasClass('active')) {
                    cartLink.addClass('active');
                }
            })
            .on('click touch', '.woocommerce-product-gallery__wrapper img', function () {
                $('.woocommerce-product-gallery__trigger').click();
            })
            .on('click touch', '#product-notification', function () {
                $('.popup-page-overlay').fadeIn(300);
                $('#single-product-notification').fadeIn(300);
            })
            .on('click touch', '.base-popup-close , .popup-page-overlay', function () {
                $('.popup-page-overlay').fadeOut(300);
                $('#single-product-notification').fadeOut(300);
            })
            .on('submit', '.single-product-form', function (e) {
                e.preventDefault();

                let formEnvelope = $('.popup-envelope');
                let formTitle = $('#single-product-notification .title');
                let sku = $('select[name=attribute_pa_sku]').val();
                if (typeof sku === 'undefined' || sku === '') {
                  sku = $(this).data('sku');
                }
                let email = $(this).find('input[name=email]').val();

                if (typeof email !== "undefined" && typeof sku !== "undefined") {
                    $.post(woocommerce_params.ajax_url, {action: "waitlist", email: email, sku: sku}, (response) => {
                        response = JSON.parse(response);
                        if (response.success && response.name) {
                            $('.pop-success-message').html(`Vöktun þín á vörunni "${response.name}" hefur verið skráð.`);
                            formEnvelope.toggle();
                            $(this).toggle();
                            formTitle.toggle();

                            setTimeout(function () {
                                $('.popup-page-overlay').click();
                            }, 3000);
                        } else {
                            $('.pop-success-message').html(response.message);
                        }
                    });
                }

            })
            .on('click touch', '.wc-variation-selection-needed', function () {
                $('.added-to-cart-item').show();
            });

        $('.categories-box:nth-child(2)').addClass('brands');
        $('.categories-box:nth-child(3)').addClass('range-price');

        $('.current-cat-parent').addClass('active');

        let brandWidth = $('.brand-item:gt(1)');
        if (brandWidth.length > 2) {
            brandWidth.addClass('brand-item--medium');
        }

        self.processProductsLoopGallery();

        if ($(window).width() > 980) {
            $(window).scroll(function () {
                let navButton = $('.navigation');
                let mobileMenuButton = $('.mobile-menu-button');
                if (navButton.hasClass('active') && mobileMenuButton.hasClass('active')) {
                    navButton.removeClass('active');
                    mobileMenuButton.removeClass('active');
                }
            });
        }

        let prevScrollpos = window.pageYOffset;
        window.addEventListener('scroll', () => {
            if(window.pageYOffset > 100){
                let currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    $('#header').removeClass('scroll-down');
                } else {
                    $('#header').addClass('scroll-down');
                    $('#header').removeClass('scroll-up');
                }
                prevScrollpos = currentScrollPos;
            }
        });



        if ($(window).width() < 980) {
            $(document)
                .on('click touch', '.nav > li', function (e) {
                    if ($(this).closest('li').children('.drop-level').length) {
                        e.preventDefault();
                        $(this).closest('li').children('.drop-level').slideToggle();
                    }

                });

            if($('.navigation').length){
                let screenHeight = screen.height;
                let headerHeight = $('header').height();
                let heightWithoutHeader = screenHeight - headerHeight;
                $('.navigation').css('height' , heightWithoutHeader + 'px');
            }
        }

        $('.index-slider').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            adaptiveHeight: true,
            dots: true,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        dots: false
                    }
                }
            ]
        });
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            fade: true,
            asNavFor: '.slider-nav',
            prevArrow: '<button type="button" class="prev"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="next"><i class="fas fa-chevron-right"></i></button>',
        });
        let detailsCarousel = $('.slider-nav .woocommerce-product-gallery__image');
        if(detailsCarousel.length <= 1){
            $('.slider-nav').addClass('non-visible');
        }else {
            $('.slider-nav').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                asNavFor: '.slider-for',
                arrows: false,
                centerMode: true,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                ]
            });
        }

        // remove links from in-slider images
        // $('.slider-nav .slick-slide .woocommerce-product-gallery__image a').contents().unwrap();


        $('.pwb-filter-products label').append("<span></span>");

        $('.orderby').select2();

        $('.search-cart-widget .icon-cart').on('click', function () {
            $('.search-cart-widget .cart-link').toggleClass('active');
        });

        let detailsSimilar = $('.similar-box .product-box');
        if (detailsSimilar.length) {
            detailsSimilar.slick({
                autoPlay: true,
                dots: false,
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 980,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            })
        }

        $(document).click(function (e) {
            let cartLink = $('.search-cart-widget .cart-link');
            if (!cartLink.is(e.target) && cartLink.has(e.target).length === 0) {
                cartLink.removeClass('active');
            }
        });

    },
    processProductsLoopGallery() {
        $('.product-link').each(function () {
            let $this = $(this);
            let productLink = $('.product-link');
            let productImage = $('.product-link img');
            if ($this.find(productImage).length > 1) {
                $this.closest(productLink).not('.no-transform').addClass('no-transform');
                $this.closest(productLink).not('.slick-initialized').slick({
                    speed: 10,
                    fade: true,
                    cssEase: 'linear'
                });
                $this.children(productImage)
                    .on('mouseenter', function () {
                        $this.closest(productLink).slick('slickNext');
                    })
                    .on('mouseleave', function () {
                        $this.closest(productLink).slick('slickPrev');
                    })
            }
        });
    }
};
