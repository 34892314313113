export default class WishList {
    static setProduct(productId, qty, type = 'wedding') {
        $.post(woocommerce_params.ajax_url, {
            prod_id: productId,
            qty: qty,
            type: type,
            action: "set_product_wish_list"
        }, (response) => {
            response = JSON.parse(response);

            if (response.success && response.message) {
                if (type === 'wedding') {
                    $("#count-wedding-items").html("(" + response.count + ")");
                }
            }
        });

        return true;
    }

    static removeProduct(productId, type) {
        $.post(woocommerce_params.ajax_url, {
            prod_id: productId,
            type: type,
            action: "remove_from_wish_list"
        }, (response) => {
            let parsed = JSON.parse(response);

            if (parsed.success) {
                $("#count-wedding-items").html("(" + parsed.count + ")");

                if (parsed.message) {
                    $(".giftlist .wrap").html(`<div class="empty-wishlist">${parsed.message}</div>`);
                }
            }
        });
    }

    static save() {
        $.post(woocommerce_params.ajax_url, {
            action: "send_wish_list"
        }, (response) => {
            response = JSON.parse(response);

            if (response.success && response.message) {
                $(".no-product-pop-up .pop-up-text").html(response.message);
                $('.no-product-pop-up').fadeIn(300);
                $('.site-overlay').addClass('overlay-cart').show();
                setTimeout(() => {
                    $('.no-product-pop-up').fadeOut(300);
                    $('.site-overlay').addClass('overlay-cart').hide();
                }, 2000);

            }
        });
    }
}
